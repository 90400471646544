import { inject, Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { delay, filter } from 'rxjs/operators';
import { AnalyticsService } from '@scpc/modules/common/services/analytics/analytics.service';
import { StorageService } from '@scpc/modules/common/services';
import { Meta } from '@angular/platform-browser';

type OptimoveAnalytics = {
  optimoveSDK: {
    API: {
      setPageVisit: (pageURL: string, pageTitle: string, pageCategory?: string, userId?: string) => void
    }
  }
};

@Injectable({ providedIn: 'root' })
export class OptimoveService extends AnalyticsService<OptimoveAnalytics> {

  private readonly router: Router = inject(Router);
  private readonly storageService: StorageService = inject(StorageService);
  private readonly meta: Meta = inject(Meta);

  constructor() {
    super();
    if (this.isBrowser) {
      /* istanbul ignore next */
      this.router.events
        .pipe(
          filter((event: NavigationEnd) => event instanceof NavigationEnd),
          delay(1000),
        )
        .subscribe(() => {
          const windowRef: OptimoveAnalytics = this.browserGlobals.windowRef();
          if (this.isBrowser && windowRef.optimoveSDK) {
            windowRef.optimoveSDK.API.setPageVisit(window.document.location.href, window.document.title, undefined, this.storageService.customerId || undefined);
          }
        });
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public event(category: string, action: string, label?: string, value?: string): void {
  }

}
